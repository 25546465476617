import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import WizNavTabs from '@component/tabs/WizNavTabs'

export type LeaderboardTab = 'main' | 'copyleader' | 'league' | 'league-all'

interface Props {
    activeTab?: LeaderboardTab
    onChangeActiveTab?: (activeTab: LeaderboardTab) => void
}

const LeaderboardTabNavigation = ({activeTab, onChangeActiveTab}: Props) => {
    const {t} = useTranslation()

    const TABS: {id: LeaderboardTab[]; label: string; link: string}[] = useMemo(
        () => [
            {id: ['main'], label: t('newLeaderboard.layout.nav.menu.mainLeaderboard'), link: '/leaderboard'},
            {
                id: ['copyleader'],
                label: t('newLeaderboard.layout.nav.menu.copyLeader'),
                link: '/leaderboard?tab=copyleader',
            },
            {
                id: ['league', 'league-all'],
                label: t('newLeaderboard.layout.nav.menu.league'),
                link: '/leaderboard?tab=league',
            },
        ],
        [t],
    )

    return (
        <WizNavTabs
            className={'my-[30px] sm:my-[20px]'}
            buttonClassName={'sm:px-[15px]'}
            tabs={TABS}
            selectedTab={activeTab}
            setSelectedTab={onChangeActiveTab}
        />
    )
}

export default React.memo(LeaderboardTabNavigation)
