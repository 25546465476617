import React, {useCallback, useRef} from 'react'
import Text from '@component/Text/Text'
import SearchModal from '@feature/leaderboard/components/SearchModal/SearchModal'
import IconSearch from '@svg/common/ic_search.svg'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'

const SearchBar = () => {
    const {t} = useTranslation()
    const searchModalRef = useRef<any>()

    const openModal = useCallback(() => {
        searchModalRef.current.show()
    }, [])

    const getContainerStyle = () => {
        const paddingStyle = 'px-[30px] py-[20px] sm:px-[20px] sm:py-[10px]'
        const spaceStyle = 'space-x-[10px]'
        const roundedStyle = 'rounded-[33px] sm:rounded-[24px]'

        return `bg-gray09 dark:bg-bg_dark_white02 flex items-center border border-gray07 dark:border-dark_gray07 cursor-pointer ${roundedStyle} ${paddingStyle} ${spaceStyle}`
    }

    return (
        <>
            <div
                className={`${getContainerStyle()}`}
                onClick={e => {
                    if (e?.cancelable) {
                        e.preventDefault()
                    }
                    createLog('event', 'leaderboard_user_search')
                    openModal()
                }}>
                <IconSearch className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />
                <Text className={'text-input text-gray02 dark:text-dark_gray02'}>
                    {t('leaderboard.searchUser.input.placeholder')}
                </Text>
            </div>
            <SearchModal ref={searchModalRef} />
        </>
    )
}

export default React.memo(SearchBar, () => true)
